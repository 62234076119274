import s from "./styles.module.scss";

export interface fileProps {
    fileUrl: string;
    fileType: string;
    fileId: string;
    fileName: string;
}

export const FileItem = (props: fileProps) => {
    const { fileUrl, fileType, fileId, fileName } = props;
    return (
        <div className={s.fileContainer}>
            <div className={s.fileItem}>
                <div className={s.preview}>
                    {fileType == "jpeg" || fileType == "jpg" || fileType == "png" ? (
                        <img src={fileUrl} />
                    ) : (
                        <iframe title="preview-item" src={fileUrl} height={400} width={200} />
                    )}
                </div>
                <div className={s.options}>
                    <a href={fileUrl} target="__blank">
                        <div className={s.buttonContainer}>
                            {/* <button className={s.view}></button> */}
                            <span>View</span>
                        </div>
                    </a>
                    {/* <div className={s.buttonContainer}>
                        <button className={s.view}></button>
                        <span>Upload</span>
                    </div> */}
                </div>
            </div>
            <p>{fileName}</p>
        </div>
    );
};
