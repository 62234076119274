import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";

import Header from "../components/layout/header/header";
import Footer from "../components/layout/footer/footer";
import { useParams } from "react-router-dom";

declare global {
    interface Window {
        dataLayer: any;
    }
}
function Main() {
    return (
        <div className="main">
            <Header />
            <Outlet />
            <Footer />
        </div>
    );
}

export default Main;
