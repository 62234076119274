import { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import Modal from "../../reusable/modal";
import { FeeEarnerBulkUpdateModalContent } from "../../modalContent/feeEarnerBulkUpdate";
// import { PocIssueBatchModalContent } from "../../modalContent/createPocIssueBatch";

export const BulkActionsView = () => {
    // const [showView, setShowView] = useState("details");
    const [bulkFeeEarner, setShowBulkFeeEarner] = useState(false);
    const [showPocIssueBatch, setShowPocIssueBatch] = useState(false);

    useEffect(() => {}, []);

    const toggleBulkModal = () => {
        setShowBulkFeeEarner(!bulkFeeEarner);
    };
    const togglePocIssueBatchModal = () => {
        setShowPocIssueBatch(!showPocIssueBatch);
    };
    return (
        <div className={styles.bulkActionsView}>
            <div className={styles.outerBulkActionsContainer}>
                <div className={styles.bulkActionsContainer}>
                    <h4>
                        <span>Bulk Update Fee Earners</span>
                        <span>
                            <button onClick={(e) => toggleBulkModal()}>Bulk Update</button>
                        </span>
                    </h4>
                    {/* <h4>
                        <span>Part 36</span>
                        <span>
                            <button onClick={(e) => togglePocIssueBatchModal()}>Create Batch</button>
                        </span>
                    </h4> */}
                </div>
            </div>
            <Modal modalState={bulkFeeEarner} previewModalAction={toggleBulkModal}>
                <FeeEarnerBulkUpdateModalContent />
            </Modal>
            {/* <Modal modalState={showPocIssueBatch} previewModalAction={togglePocIssueBatchModal}></Modal> */}
        </div>
    );
};
