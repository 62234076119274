import { useEffect, useState } from "react";
import axios from "axios";
import { Loading } from "notiflix";
import { Report } from "notiflix/build/notiflix-report-aio";
import api from "../helpers/apiRequester";
import { useNavigate } from "react-router-dom";

function Auth() {
    const navigate = useNavigate();
    const [authorizing, setAuthorizing] = useState(false);
    useEffect(() => {
        if (!authorizing) {
            setAuthorizing(true);
            Loading.circle("Authenticating...");
            startAuth();
        }
    }, [authorizing]);

    const startAuth = async () => {
        try {
            const response = await getOauthTokens();
            const { id_token, access_token, refresh_token } = response;

            window.sessionStorage.setItem("access", access_token);
            window.sessionStorage.setItem("id", id_token);
            window.sessionStorage.setItem("refresh", refresh_token);

            const user = await getOauthUser(access_token);

            // console.log("ACCESS TOKEN...", access_token);

            window.sessionStorage.setItem("email", user.email);
            const apiResult = await api({ url: `/init` });

            if (apiResult.data === "SUCCESS") {
                Loading.remove();
                navigate("/home");
            } else {
                Loading.remove();
                Report.failure("An Error Occurred", "An error occurred please log in again to continue.", "Okay", () => {
                    window.location.href = `https://${process.env.REACT_APP_DASHBOARD_LOGIN}`;
                });
            }
        } catch (error: any) {
            if (error.response) {
                console.log("OAUTH ERROR: ", error.response.data);
                const authError = error.response.data.error;
                console.log("error:", error);

                if (authError == "invalid_grant") {
                    Loading.remove();
                    Report.failure("An Error Occurred", "An error occurred please log in again to continue.", "Okay", () => {
                        window.location.href = `https://${process.env.REACT_APP_DASHBOARD_LOGIN}`;
                    });
                } else {
                    Report.failure("An Error Occurred", "An error occurred please log in again to continue.", "Okay", () => {
                        window.location.href = `https://${process.env.REACT_APP_DASHBOARD_LOGIN}`;
                    });
                }
            }
        }
    };

    const getOauthUser = async (access_token: string) => {
        // console.log("------------ GET AUTH USER \n \n");
        const config = {
            headers: {
                Authorization: `Bearer ${access_token}`,
            },
        };
        const oAuthUserResponse = await axios.get(`${process.env.REACT_APP_OAUTH_USER}`, config);
        return oAuthUserResponse.data;
    };

    const getOauthTokens = async () => {
        try {
            console.log("------------ GET AUTH TOKEN \n \n");
            const config = {
                headers: {
                    "content-type": "application/x-www-form-urlencoded",
                    Authorization: `Basic ${process.env.REACT_APP_COGINTO_AUTHORIZATION}`,
                },
            };
            const search = window.location.search;
            const urlParams = new URLSearchParams(search);
            const code = urlParams.get("code");
            const params = new URLSearchParams();
            params.append("grant_type", "authorization_code");
            params.append("client_id", `${process.env.REACT_APP_COGNITO_CLIENT_ID}`);
            params.append("code", `${code}`);
            params.append("scope", "email");
            params.append("redirect_uri", `${process.env.REACT_APP_COGNITO_REDIRECT_URI}`);

            // console.log("OAUTH TOKEN PARAMS: ", params.toString());
            const oAuthresponse = await axios.post(`${process.env.REACT_APP_OAUTH}`, params, config);
            // console.log("------------ RECEIVED AUTH TOKEN \n \n");
            return oAuthresponse.data;
        } catch (error) {
            console.log("error getting OAUTH TOKEN: ", error);
            return false;
        }
    };

    return <div className="auth"></div>;
}

export default Auth;
