import { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { Loading } from "notiflix";
import { Report } from "notiflix/build/notiflix-report-aio";
import api from "../../../../helpers/apiRequester";
import Select from "react-select";
import { selectObjectProps } from "../../../../interfaces";
import { InputFieldDate } from "../../../reusable/inputFieldDate";
import { yesNo } from "../../../../data";

export interface statsProps {
    data: any;
}

export const CourtDatesView = (props: statsProps) => {
    const { data } = props;
    const [editing, setEditing] = useState(false);
    const [caseData, setCaseData] = useState(data);
    const [dataChanged, setDataChanged] = useState(false);

    useEffect(() => {
        // console.log("CASE INFO DATAVIEW : ", data);
        setCaseData(data);
        console.log(data);
    }, [data]);

    useEffect(() => {
        // console.log("CASE INFO DATAVIEW : ", data);
        console.log(data);
    }, [caseData]);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    };

    const saveData = async () => {
        Loading.circle("Saving");
        setEditing(false);
        // console.log("SAVE DATA......", caseData);
        try {
            const apiResult = await api({
                url: "save-case-info",
                data: { caseDataToSave: caseData },
            });
            Loading.remove();
            if (!apiResult.data.success) return Report.failure("Error", "An error occurred.", "Okay");

            Report.success("Success", "Your update saved successfully.", "Okay");
        } catch (error) {
            Report.failure("Error", "Couldnt save data.", "Okay");
        }
    };

    const handleEditToggle = () => {
        if (editing) {
            setCaseData(data);
        }
        setEditing(!editing);
    };

    const onDateChange = (data: { field: string; value: string }) => {
        console.log("input date: ", data);
        if (data != null) {
            let tempCaseData = { ...caseData };
            tempCaseData[data.field] = data.value;
            // console.log("data to save : ", tempCaseData);

            if (data.field == "noi_deemed_served_date") {
                let estimateDate = new Date(data.value);
                estimateDate.setMonth(estimateDate.getMonth() + 6);
                tempCaseData.estimated_settlement_date = estimateDate;
            }
            setCaseData(tempCaseData);
            setDataChanged(true);

            return { success: true, error: "" };
        }

        return { success: false, error: "Cant save data." };
    };

    const checkValue = (field: string) => {
        // console.log("CASE DATA: ", caseData);

        let fieldValue = caseData[field];
        if (typeof fieldValue == "undefined" || fieldValue == null || fieldValue == "") {
            return "";
        }

        console.log(" field : ", field);
        console.log(" caseData[field] : ", caseData[field]);
        console.log("fieldValue : ", fieldValue);
        console.log("typeof fieldValue : ", typeof fieldValue);

        // fieldValue = typeof fieldValue == "string" && fieldValue.includes("T") ? fieldValue.split("Z")[0] : fieldValue;
        return new Date(fieldValue);
        // if (field == "poc_issued_date") {
        // }
        return "";
    };

    const clearValue = (field: string) => {
        if (!editing) return;
        let tempCaseData = { ...caseData };
        tempCaseData[field] = "";
        console.log("CASE DATA clear: ", tempCaseData);

        setCaseData(tempCaseData);
        setDataChanged(true);
    };

    return (
        <div className={styles.detailsView}>
            <h4>
                <span>Court Milestones</span>
                <span>
                    <button onClick={(e) => handleEditToggle()}>{editing ? "Cancel" : "Edit"}</button>
                    {editing && dataChanged ? (
                        <>
                            <button onClick={(e) => saveData()}>Save</button>
                        </>
                    ) : (
                        <></>
                    )}
                </span>
            </h4>
            <div className={styles.viewContainer}>
                <form onSubmit={(e) => handleSubmit(e)}>
                    <fieldset>
                        <label>
                            Issue Date <span onClick={() => clearValue("poc_issued_date")}>Clear</span>
                        </label>
                        <InputFieldDate
                            fieldName="poc_issued_date"
                            saveValue={onDateChange}
                            editable={!editing}
                            prepopulate={checkValue("poc_issued_date")}
                        />
                    </fieldset>
                    <fieldset>
                        <label>Estimated Settlement date</label>
                        <InputFieldDate
                            fieldName="estimated_settlement_date"
                            saveValue={onDateChange}
                            editable={true}
                            prepopulate={checkValue("estimated_settlement_date")}
                        />
                    </fieldset>
                    <h5>
                        <span>Acknowledgement of Service</span>
                    </h5>
                    <fieldset></fieldset>
                    <fieldset>
                        <label>
                            Due Date <span onClick={() => clearValue("ack_due_date")}>Clear</span>
                        </label>
                        <InputFieldDate
                            fieldName="ack_due_date"
                            saveValue={onDateChange}
                            editable={!editing}
                            prepopulate={checkValue("ack_due_date")}
                        />
                    </fieldset>
                    <fieldset>
                        <label>
                            Received Date <span onClick={() => clearValue("ack_received_date")}>Clear</span>
                        </label>

                        <InputFieldDate
                            fieldName="ack_received_date"
                            saveValue={onDateChange}
                            editable={!editing}
                            prepopulate={checkValue("ack_received_date")}
                        />
                    </fieldset>
                    <h5>
                        <span>Notice of Issue</span>
                    </h5>
                    <fieldset></fieldset>
                    <fieldset>
                        <label>
                            Received Date <span onClick={() => clearValue("noi_received_date")}>Clear</span>
                        </label>
                        <InputFieldDate
                            fieldName="noi_received_date"
                            saveValue={onDateChange}
                            editable={!editing}
                            prepopulate={checkValue("noi_received_date")}
                        />
                    </fieldset>
                    <fieldset>
                        <label>
                            Deemed Served Date <span onClick={() => clearValue("noi_deemed_served_date")}>Clear</span>
                        </label>
                        <InputFieldDate
                            fieldName="noi_deemed_served_date"
                            saveValue={onDateChange}
                            editable={!editing}
                            prepopulate={checkValue("noi_deemed_served_date")}
                        />
                    </fieldset>
                    <h5>
                        <span> Notice of proposed Allocation</span>
                    </h5>
                    <fieldset></fieldset>
                    <fieldset>
                        <label>
                            NOPA Date Received <span onClick={() => clearValue("nopa_date_received")}>Clear</span>
                        </label>
                        <InputFieldDate
                            fieldName="nopa_date_received"
                            saveValue={onDateChange}
                            editable={!editing}
                            prepopulate={checkValue("nopa_date_received")}
                        />
                    </fieldset>
                    <fieldset>
                        <label>
                            NOPA Date Due <span onClick={() => clearValue("nopa_date_due")}>Clear</span>
                        </label>
                        <InputFieldDate
                            fieldName="nopa_date_due"
                            saveValue={onDateChange}
                            editable={!editing}
                            prepopulate={checkValue("nopa_date_due")}
                        />
                    </fieldset>
                </form>
            </div>
        </div>
    );
};
