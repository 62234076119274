import { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { Loading } from "notiflix";
import { Report } from "notiflix/build/notiflix-report-aio";
import { Confirm } from "notiflix/build/notiflix-confirm-aio";
import api from "../../../helpers/apiRequester";

interface bulkSelectorInterface {
    saveGroup: (groupToSave: Array<any>) => void;
}

export const BulkCaseSelector = (props: bulkSelectorInterface) => {
    const { saveGroup } = props;
    const [caseGroup, setCaseGroup] = useState<any>([]);
    const [tableData, setTableData] = useState<any>([]);

    const onChange = async (e: React.FormEvent<HTMLInputElement>) => {
        const newValue = e.currentTarget.value;
        if (newValue.length > 2) {
            try {
                const apiResult = await api({
                    url: "search-case",
                    data: { term: newValue.trim() },
                });
                if (apiResult.data.results.length < 1) {
                    return Report.failure("No Result", "No results match that search term.", "Okay");
                }

                setTableData(apiResult.data.results);
            } catch (error) {
                Report.failure("Search Error Occurred", "An error occurred whilst searching for your term. Please contact administrator.", "Okay");
            }
        }
        if (newValue.length == 0) {
            setTableData([]);
        }
    };

    const removeCase = (case_ref: string) => {
        console.log("remove: ", case_ref);
        const tempGroup = caseGroup.filter((e: any) => e !== case_ref);
        setCaseGroup(tempGroup);
        saveGroup(tempGroup);
    };
    const addCase = (case_ref: string) => {
        console.log("add: ", case_ref);
        if (caseGroup.some((cf: string) => cf == case_ref)) {
            return;
        }
        let tempGroup = [...caseGroup, case_ref];
        setCaseGroup(tempGroup);
        saveGroup(tempGroup);
    };

    return (
        <div className={styles.bulkCaseSelectorContainer}>
            <div className={styles.searchContainer}>
                <h4>Search Cases</h4>
                <input className={styles.search} type="search" onChange={onChange} placeholder="Search by case reference" />
            </div>
            <div className={styles.bulkLists}>
                <div className={styles.listContainer}>
                    <h5>Selected Cases</h5>
                    <div className={styles.selectedCases}>
                        {caseGroup &&
                            caseGroup.map((item: string, k: number) => {
                                return (
                                    <button
                                        key={k}
                                        onClick={(e) => {
                                            removeCase(item);
                                        }}
                                    >
                                        {item}
                                        <span>Remove</span>
                                    </button>
                                );
                            })}
                    </div>
                </div>
                <div className={styles.listContainer}>
                    <h5>Search Results</h5>
                    <div className={styles.searchResults}>
                        {tableData.length > 0 &&
                            tableData.map((caseData: any, k: number) => {
                                return (
                                    <button
                                        key={k}
                                        onClick={(e) => {
                                            addCase(caseData.case_ref);
                                        }}
                                    >
                                        {caseData.case_ref}
                                        <span>Add</span>
                                    </button>
                                );
                            })}
                    </div>
                </div>
            </div>
        </div>
    );
};
