import { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { StatTile, ViewTitle } from "../../../reusable";
import { Loading } from "notiflix";
import { Report } from "notiflix/build/notiflix-report-aio";
import { useNavigate } from "react-router-dom";
import api from "../../../../helpers/apiRequester";
import { CaseView } from "../../caseViewContainer";
export interface statsProps {
    data: any;
}
export const AllCases = (props: statsProps) => {
    const navigate = useNavigate();
    const { data } = props;
    const [currentCase, setCurrentCase] = useState(data[0]);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    };

    const handleClick = (caseRef: string) => {
        const caseToSet = data.find((matter: any) => matter.case_ref == caseRef);
        console.log("case ref to set: ", caseToSet);
        console.log("case ref to set: ", caseRef);

        setCurrentCase(caseToSet);
    };

    const buttonState = (dupe: number, cancelled: number) => {
        if (dupe === 1) {
            return "Duplicate";
        }
        if (cancelled === 1) {
            return "Cancelled";
        }
        return "Active";
    };

    return (
        <div className={styles.allCasesView}>
            <h3>All Cases </h3>
            <div className={styles.viewContainer}>
                <div className={styles.caseSelector}>
                    {data.map((item: any, k: number) => {
                        return (
                            <button
                                className={`${currentCase.case_ref == item.case_ref ? styles.selected : ""} ${styles.caseButton} ${
                                    item.cancelled ? styles.inactiveCase : styles.activeCase
                                }`}
                                key={k}
                                onClick={(e) => {
                                    handleClick(item.case_ref);
                                }}
                            >
                                <span>{buttonState(item.marked_duplicate, item.cancelled)}</span>
                                <span>{item.case_ref}</span>
                            </button>
                        );
                    })}
                </div>
                <div className={styles.caseViewContainer}>
                    <CaseView data={currentCase} />
                </div>
            </div>
        </div>
    );
};
