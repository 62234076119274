import { useEffect, useState } from "react";
import { Loading } from "notiflix";
import { Report } from "notiflix/build/notiflix-report-aio";
import { useNavigate, useParams } from "react-router-dom";
import Sidebar from "../components/layout/sidebar/sidebar";
import { MainView } from "../components/layout/main";
import { StatTile, ViewTitle } from "../components/reusable";
import api from "../helpers/apiRequester";
import { ClientView } from "../components/views/singleClientView";
import { CaseView } from "../components/views/caseViewContainer";
import { AllCases } from "../components/views/subViews/clientCasesViewContainer";

function ViewClient() {
    const navigate = useNavigate();
    const [allCaseData, setAllCaseData] = useState<any>();
    const [clientData, setClientData] = useState<any>();
    const [clientID, setClientID] = useState<string>("");
    const { url_client } = useParams();

    useEffect(() => {
        if (url_client && clientID == "") {
            setClientID(url_client);
            Loading.circle("Loading...");
            // console.log("LOADING.... GET CASE DATA");
            getData(url_client);
        }
    }, [clientID]);

    const getData = async (url_client: string) => {
        try {
            // console.log("CASEREF: ", url_case_ref);

            const apiResponse = await api({
                url: "view-client",
                data: { client_id: url_client },
            });
            const data = apiResponse.data;

            if (data.success) {
                // console.log("cases: ", data.allCaseInfo.length);
                // console.log("clientInfo: ", data.clientInfo);

                setAllCaseData(data.allCaseInfo);
                setClientData(data.clientInfo);
            }

            // console.log("CASE DATA: ", data);
            Loading.remove();
        } catch (error: any) {
            console.log(error);
            Loading.remove();
            if (error?.response.status == 401) {
                Report.failure("Session Timed Out", "Please log in again to continue.", "Okay", () => {
                    window.location.href = `https://${process.env.REACT_APP_DASHBOARD_LOGIN}`;
                });
            } else {
                console.log("error getting data from api....");
                // window.location.href = `https://${process.env.REACT_APP_DASHBOARD_LOGIN}`;
            }
        }
    };

    return (
        <div className="viewCase">
            <Sidebar />
            <MainView>
                <ViewTitle text={`${clientData ? clientData.first_name + " " + clientData?.last_name : ""}`} />
                <div>
                    {clientData ? <ClientView data={clientData} /> : <></>}
                    {allCaseData ? <AllCases data={allCaseData} /> : <></>}
                </div>
            </MainView>
        </div>
    );
}

export default ViewClient;
