import s from "./styles.module.scss";
import { useEffect, useState } from "react";

import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { enGB } from "date-fns/locale";

registerLocale("en-GB", enGB);

interface inputFieldDateProps {
    fieldName: string;
    editable: boolean;
    prepopulate?: any;
    saveValue: (obj: { field: string; value: string }) => { success: boolean; error: string };
}

export const InputFieldDate = (props: inputFieldDateProps) => {
    const { saveValue, fieldName, editable, prepopulate } = props;
    const [valueToSave, setValueToSave] = useState<any>(prepopulate ? prepopulate : "");

    useEffect(() => {
        setValueToSave(prepopulate);
    }, [prepopulate]);

    const [error, setError] = useState("");
    const onChangeFunc = (e: Date | null | string) => {
        if (e != null) {
            setError("");
            // console.log("INPUT DATE FIELD CHANGED...", newValue);
            let date = new Date(e);

            date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
            const dateString = String(new Date(date).toISOString());

            setValueToSave(e);
            const saveResult = saveValue({ field: fieldName, value: dateString });
            if (!saveResult.success) {
                setError(saveResult.error);
            }
        }
    };
    return (
        <>
            <DatePicker
                dateFormat="dd/MM/yyyy"
                dropdownMode="select"
                showMonthDropdown
                // adjustDateOnChange
                showYearDropdown
                selected={valueToSave}
                onChange={(date) => onChangeFunc(date)}
                className={s.inputDateStyle}
                placeholderText={"Select Date"}
                readOnly={editable}
                locale={enGB}
            />
            <span className={`${s.notice} {${error.length < 1 ? "error" : ""}`}>{error}</span>
        </>
    );
};
