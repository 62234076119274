import { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { FileItem, StatTile, ViewTitle } from "../../../reusable";
import { Loading } from "notiflix";
import { Report } from "notiflix/build/notiflix-report-aio";
import { useNavigate } from "react-router-dom";
import api from "../../../../helpers/apiRequester";
import Select from "react-select";
import { ProgressView } from "../progressView";

export const FilesView = (props: { caseRef: string }) => {
    const navigate = useNavigate();
    const { caseRef } = props;
    const [dataReady, setDataReady] = useState(false);
    const [noTokenFiles, setNoTokenFiles] = useState([]);
    const [tokenFiles, setTokenFiles] = useState([]);

    useEffect(() => {
        if (!dataReady) {
            getData();
        }
    }, [dataReady]);

    useEffect(() => {
        Loading.circle("Getting Files...");
        getData();
    }, [caseRef]);
    const getData = async () => {
        try {
            const apiResponse = await api({ url: "/all-files", data: { case_ref: caseRef } });
            const data = apiResponse.data;

            if (data.success) {
                setTokenFiles(data.tokenFiles);
                setNoTokenFiles(data.noTokenFiles);
                setDataReady(true);
            }
            Loading.remove();
        } catch (error: any) {
            console.log(error);
            Loading.remove();
            if (error?.response.status == 401) {
                Report.failure("Session Timed Out", "Please log in again to continue.", "Okay", () => {
                    window.location.href = `https://${process.env.REACT_APP_DASHBOARD_LOGIN}`;
                });
            } else {
                console.log("");
                window.location.href = `https://${process.env.REACT_APP_DASHBOARD_LOGIN}`;
            }
        }
    };

    return (
        <div className={styles.filesView}>
            <h4>Cloud Storage</h4>
            <div className={styles.viewFilesContainer}>
                {noTokenFiles && noTokenFiles.length > 0 ? (
                    noTokenFiles.map((filegroup: any, i: number) => {
                        return (
                            <div className={styles.fileGroup} key={i}>
                                <h4>{filegroup.title.split("_").join(" ")}</h4>
                                <div className={styles.files}>
                                    {filegroup.files.map((file: any, i2: number) => (
                                        <FileItem
                                            key={i2}
                                            fileName={file.filename}
                                            fileId={file.filekey}
                                            fileType={file.fileType}
                                            fileUrl={file.signedUrl}
                                        />
                                    ))}
                                </div>
                            </div>
                        );
                    })
                ) : (
                    <></>
                )}
                {tokenFiles && tokenFiles.length > 0 ? (
                    tokenFiles.map((filegroup: any, i: number) => {
                        return (
                            <div className={styles.fileGroup} key={i}>
                                <h4>{filegroup.title.split("_").join(" ")}</h4>
                                <div className={styles.files}>
                                    {filegroup.files.map((file: any, i2: number) => (
                                        <FileItem
                                            key={i2}
                                            fileName={file.filename}
                                            fileId={file.filekey}
                                            fileType={file.fileType}
                                            fileUrl={file.signedUrl}
                                        />
                                    ))}
                                </div>
                            </div>
                        );
                    })
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
};
